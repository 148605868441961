import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';


const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero.png" }) {
        childImageSharp {
          fluid(maxWidth: 1200) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Img
      style={{ height: 'calc(100vh - 75px)', width: '100vw' }}
      imgStyle={{ height: 'calc(100vh - 75px)' }}
      fluid={data.placeholderImage.childImageSharp.fluid}
    />
  );
};

export default Image;
