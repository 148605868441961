import React from 'react';
import classnames from 'classnames';

import './Map.scss';


const Map = ({ size = 'Small', src }) => (
  <div className={classnames('Map', size)}>
    <iframe
      allowFullScreen=""
      aria-hidden="false"
      frameBorder="0"
      height="100%"
      src={src}
      width="100%"
      style={{ border: 0 }}
      tabIndex="0"
    />
  </div>
);

export default Map;
