import React from 'react';
import { Link } from 'gatsby';
import { useIntl } from 'gatsby-plugin-intl';

import MainLogo from '../../images/adc_logo_transparent1.png';
import LanguageSelect from '../LanguageSelect/LanguageSelect';

import HamburgerMenu from './HamburgerMenu';

import './Header.scss';


const Header = () => {
  const { formatMessage } = useIntl();

  const navLinks = [
    { label: formatMessage({ id: 'About us' }), to: 'about' },
    { label: formatMessage({ id: 'Team' }), to: 'team' },
    { label: formatMessage({ id: 'Services' }), to: 'services' },
    { label: formatMessage({ id: 'Contact' }), to: 'contact' },
  ];

  const handleRedirect = id => {
    const section = document.getElementById(id);

    window.scrollTo({
      top: section.offsetTop - 75,
      left: 0,
      behavior: 'smooth',
    });
  };

  const renderNavigationLinks = () => (
    navLinks.map(link => (
      <div className="MainNavigation_Link" onClick={() => handleRedirect(link.to)} key={link.to} to={link.to} >
        {link.label}
      </div>
    ))
  );

  return (
    <header className="MainHeader">
      <div className="MainHeader_ContentWrapper">
        <Link to="/">
          <div className="LogoContainer">
            <img src={MainLogo} alt="Logo ADC" />
          </div>
        </Link>

        <div className="vertical-center">
          <LanguageSelect />

          <nav className="MainNavigation">
            {renderNavigationLinks()}
          </nav>

          <HamburgerMenu renderNavigationLinks={renderNavigationLinks} />
        </div>
      </div>
    </header>
  );
};

export default Header;
