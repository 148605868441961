import React from 'react';
import classnames from 'classnames';

import './Text.scss';


const Text = ({ children, className }) => (
  <p className={classnames('Text', className)}>{children}</p>
);

export default Text;

