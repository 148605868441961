import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import './Footer.scss';


const Footer = () => (
  <footer className="MainFooter">
    <span>Copyright 2020</span>
    <span>{useIntl().formatMessage({ id: 'ADC' })}</span>
    <span>{useIntl().formatMessage({ id: 'All rights reserved' })}</span>
  </footer>
);

export default Footer;
