import React, { useState } from 'react';
import classnames from 'classnames';


const HamburgerMenu = ({ renderNavigationLinks }) => {
  const [isMenuOpen, openMenu] = useState(false);

  return (
    <div
      className="HamburgerMenu"
      onClick={() => openMenu(!isMenuOpen)}
    >
      <span className={classnames('Hamburger', { Open: isMenuOpen })} />

      {isMenuOpen && (
        <div className="MobileNav">
          <div className="MobileNavLinksContainer">
            {renderNavigationLinks()}
          </div>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
