import React from 'react';
import classnames from 'classnames';
// import ScrollAnimation from 'react-animate-on-scroll';

import './ContentSection.scss';


const ContentSection = ({ className, children, id, title, wrapperClassName }) => {
  return (
    <section className="ContentSection" id={id}>
      <h3 className="ContentSection_Title">{title}</h3>

      <div className={classnames('Wrapper', wrapperClassName)}>
        <div className={classnames('MainContentWrapper', className)}>
          {children}
        </div>
      </div>
    </section>
  );
};

export default ContentSection;
